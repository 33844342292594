<template>
    <div class="activeList el-content">
		<a-space class="mb12">
			<a-button type="primary" v-has="{action:'wxapp_adhibition_active_edit'}" @click='addactivetype(0)'>
				<i class="ri-add-line"></i>新增分类
			</a-button>
			<a-button type="danger" v-has="{action:'wxapp_adhibition_active_type_del'}" @click="deleteType(pks)">
				<i class="ri-delete-bin-line"></i>批量删除
			</a-button>
		</a-space>
		<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
			{title:'ID',dataIndex:'id'},
			{title:'分类名称',dataIndex:'name'},
			{title:'是否显示',dataIndex:'is_put_away',slots:{customRender:'is_put_away'}},
			{title:'排序',dataIndex:'rank'},
			{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
		]" :row-selection="{ selectedRowKeys: pks, onChange:(e)=>{pks = e}  }">
			<template #is_put_away="{record}">
				<a-tag :color="record.is_put_away == 1 ?'#00CC66':'#999'">{{record.is_put_away == 1 ?'显示':'不显示'}}</a-tag>
			</template>
			<template #action="{record}">
				<a-space>
					<kd-button type="primary" title="编辑" icon="ri-edit-line" v-has="{action:'wxapp_adhibition_active_edit'}"  @click="addactivetype(record)"></kd-button>
					<kd-button type="danger" title="删除" icon="ri-delete-bin-5-line" v-has="{action:'wxapp_adhibition_active_type_del'}"  @click="deleteType(record.id)"></kd-button>
				</a-space>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange="(p,e)=>{getActiveType(info.page,e)}"
				@change="(e)=>{getActiveType(e,info.limit)}"
			/>
		</div>
		<a-modal v-model:visible="actvetype" title="添加活动分类" @ok="submitForm" width="600px" @cancel="actvetype = false">
			<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="活动分类"><a-input v-model:value="ruleForm.name"></a-input></a-form-item>
				<a-form-item label="活动排序">
					<a-input v-model:value="ruleForm.rank" type="number"></a-input>
				</a-form-item>
				<a-form-item label="是否显示">
				    <a-radio-group v-model:value="ruleForm.is_put_away">
				        <a-radio :value="1">显示</a-radio>
				        <a-radio :value="0">隐藏</a-radio>
				    </a-radio-group>
				</a-form-item>
			</a-form>
		</a-modal>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import activeModel from '@/api/addons/active';
import commonModel from '@/api/common';
export default {
	setup(){
		const state = reactive({
			info: {
                list: [],
                page: 1,
                count: 10,
                limit: 10
            },
            ruleForm:null,
            actvetype: false,
            pks:[],
            id: 0
		})
		getActiveType(1,state.info.limit)

		function getActiveType(page,limit){
			activeModel.getActiveType(page,limit,res=>state.info = {limit,...res})
		}

		function  addactivetype(data){
			state.ruleForm = {
				id:data.id ? data.id : 0,
				name:data.name ? data.name:'',
				rank:data.rank ? data.rank:99,
				is_put_away:data.is_put_away ? data.is_put_away:0,
			}
			state.actvetype = true
        }

		const deleteType = (id)=>commonModel.deleteDataList(id,48,"确认删除分类吗?").then(()=>{
			getActiveType(state.info.page,state.info.limit)
		})

		const submitForm = ()=>activeModel.addOrEditActiveType(state.ruleForm,()=>{
			state.actvetype = false
			getActiveType(state.info.page,state.info.limit)
		})

		return{
			...toRefs(state),
			getActiveType,
			addactivetype,
			deleteType,
			submitForm
		}
	}
}
</script>

<style lang="scss">
    
</style>
